.tableau {
    overflow: hidden;
    position: relative;
    height: 150px;
    width: 150px;
    margin: 0 auto;
    border-radius: 100%;

    clip-path: circle(400px at 300px 300px);
    animation: fade-out-image 5s 1s ease infinite;

    .ground {
        position: absolute;
        margin-top: 105px;
        height: 50px;
        width: 100%;
        transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    }

    .pyramid1left {
        position: absolute;
        margin-top: 60px;
        margin-left: 40px;
        height: 200px;
        width: 320px;
        background: #efefef;
        clip-path: polygon(0% 100%, 100% 100%, 50% 0%);
        bottom: 10rem;
        left: -3rem;
        transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    } 
    .pyramid1right {
        position:  absolute;
        z-index: 1;
        margin-top: 60px;
        margin-left: 200px;
        height: 200px;
        width: 200px;

        clip-path: polygon(30% 100%, 100% 100%, 0% 0%);
        bottom: 10rem;
        left: -3rem;
        transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    }


}