







    

        

        
       
        
   
    
    
        




    form {
        width: calc(calc(100% - 50px) + 10px);
        text-align: left;
        background: rgba(255, 255, 255, 1);
        font-family: var(--font-family);

        width: min(1000px, 80%, 100vw);
        .formInner.dark{
            background: rgb(36, 37, 38);
        }

        .formInner{
            border: 1px solid;
            border-color: var(--dark-purple);
            
        }

            h1{
                color: rgb(0,0,0,0.6);
                font-size: 2.5rem;
                font-weight: 700;
                letter-spacing: 0.175rem;
                text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);
                
            }
            p {
                font-size: 1.3rem;
                text-align: center;
                line-height: 1.9;
                padding: 20px;
                        
            }


             
        h2 {

            
           
            display: block;
            font-size: 50px;
            font-weight: 700;
            text-transform: uppercase;
            color: var(--text-dark);
            margin: 0;
            text-align: center;
            
        }

    }
    
    
    .ct__formWrap{
        display: flex;
    align-items: center;
    justify-content: center;
    }
    
    
    .ctForm__input{
        
       display: flex;
       flex-direction: column;
       overflow: hidden;
       padding-top: 60px;
    
      
    
    
           
    }
    
    
    
        
    
    
    
    
    .ctForm__inputItems{
    
        display: flex;
        padding: 10px 0 20px;
    
    
       
       
    
        div {
            display: flex;
            flex-direction: column;
            flex: 1;
    
            label{
                
                font-size: 0.99rem;
                font-weight: 400;
                color: #636363;
                
            }
    
         
    
           
        }
    
    
        input {
            appearance: none;
            display: block;
            width: 100%;
            height: 40px;
            font-size: 15px;
            font-weight: 400;
            border: none;
            border-bottom-color: currentcolor;
            border-bottom-style: none;
            border-bottom-width: medium;
            border-bottom: 1px solid #a5a5a5;
            border-radius: 0;
            padding: 0 !important;
            color: #636363;
            -webkit-box-shadow: none;
            box-shadow: none;
            background: none;
            outline: none;
            font-family: var(--font-family);

            font-size: 1.27rem;
            text-transform: none;
    
    
            
            
        }
    
        .contactInputspace{
    
            margin-left: 20px;
        }
    
        textarea {
            appearance: none;
            display: block;
            width: 100%;
            height: 40px;
            font-size: 15px;
            font-weight: 400;
            border: none;
            border-bottom-color: currentcolor;
            border-bottom-style: none;
            border-bottom-width: medium;
            border-bottom: 1px solid #a5a5a5;
            border-radius: 0;
            padding: 0 !important;
            color: #636363;
            -webkit-box-shadow: none;
            box-shadow: none;
            background: none;
            outline: none;
    
            height: 65px;
            resize: none;
            margin-top: 15px;
            font-family: var(--font-family);


            font-size: 1.27rem;
            text-transform: none;
        }


        
    }

    .buttonWrap{
        padding-bottom: 25px;
    }


    .button.dark{
        background: var(--dark-purple);

        &:hover{
               
            background: linear-gradient(145deg, #f3f9ff, #e9f5ff);
            background: rgb(36, 37, 38);

            color:var(--dark-purple);
            border: 1px solid;
            border-color:var(--dark-purple) ;

            svg{
                font-size: 1.4rem;
                padding-left: 6px;
                border-color:var(--dark-purple) ;
          
    
            }
    
        }
    }
    .button{
        font-family: var(--font-family);
        cursor: pointer;

        svg{
            font-size: 1.4rem;
            padding-left: 6px;
      

        }
        
        border-radius: 5px;
        display: block;
        width: 175px;
        padding: 16px;
        margin: 0 auto;
        font-size: 18px;
        font-weight: 400;
        letter-spacing: 0.09rem;
        color: #fff;
        background: linear-gradient(145deg, #3a75b4, #003156);
        background:var(--dark-purple);
        box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
        display: flex;
        justify-content:center;
        align-items: center;
        border: 1px solid transparent;
        transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
        
        
             &:hover{
               
                 background: linear-gradient(145deg, #f3f9ff, #e9f5ff);
                 background: rgba(255,255,255,0.8);

                 color: #183d64;
                 border: 1px solid #183d64;

                 color:var(--dark-purple);
                 border: 1px solid;
                 border-color:var(--dark-purple) 
         
             }
    }