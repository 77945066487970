.container{

    position: relative;
    left: 0;
    right: 0;
    bottom:0;
    height: 60px;
    overflow: hidden;
    display: flex;
    justify-content: flex-end;
    align-items: center;


}

.iconWrap{

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 1;
    width: 150px;

    .footerLinkWrap{
        height: 40px;
        width: 40px;
        position: relative;
        font-size: 2rem;
        cursor: pointer;

        svg{
            max-height: 100%;
            max-width: 100%;
            position: absolute;
            object-fit: contain;
            left: 0;
        }
      
    }
}



.crystalBottom{

    width: 100%;
    height: 20px;
    background: linear-gradient(45deg, rgba(120, 120, 250, 0.5) 10%, transparent 10%) repeat scroll 0% 0%, linear-gradient(65deg, transparent 70%, rgba(120, 120, 250, 0.5) 70%) repeat scroll 0% 0%, linear-gradient(15deg, rgba(120, 120, 250, 0.5) 80%, transparent 80%) repeat scroll 0% 0%, linear-gradient(25deg, rgba(120, 120, 250, 0.5) 30%, transparent 30%) repeat scroll 0% 0%, linear-gradient(-35deg, rgba(120, 120, 250, 0.5) 50%, transparent 50%) repeat scroll 0% 0%, rgba(120, 120, 250, 0.5);
}


.ground{

    position: absolute;
    
    height: 20px;
    width: 100%;
}


.pyramid1left {
    position: absolute;
    margin-top: 60px;
    margin-left: 40px;
    height: 60px;
    width: 180px;
    background: #efefef;
    clip-path: polygon(0% 100%, 100% 100%, 50% 0%);
    bottom: 10rem;
   
    transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
} 
.pyramid1right {
    position:  absolute;
    z-index: 1;
    margin-top: 60px;
    margin-left: 200px;
    height: 60px;
    width: 60px;

    clip-path: polygon(30% 100%, 100% 100%, 0% 0%);
    bottom: 10rem;
    left: -3rem;
    transition: all 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
}








.waveWrap{
height: 60px;
  width:100%;
  position:absolute;
  bottom:0;
  left:0;

  svg{
    position: absolute;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
    color: rgb(90, 0, 192);
    
    &:nth-of-type(2) {
      animation: wave 7s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.125s infinite, swell 7s ease -1.25s infinite;
      opacity: 1;
      color: rgb(145, 115, 235);
      stroke:rgb(145, 115, 235) ;
      fill:rgb(145, 115, 235);
    }

    @keyframes wave {
      0% {
        margin-left: 0;
      }
      100% {
        margin-left: -1600px;
      }
    }
    
    @keyframes swell {
      0%, 100% {
        transform: translate3d(0,-25px,0);
      }
      50% {
        transform: translate3d(0,5px,0);
      }
    }

  }
}

