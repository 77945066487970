.container{

  
  .inner.dark{
    background: #242526
  }
  
}

.inner{
    display: grid;
    grid-gap: 0.5rem;
    grid-template-columns: repeat(2, minmax(0, 2fr));
    justify-items:center; 
    background: #E4E6EB;


    @media screen and (min-width: 80px) and (max-width: 678px){            
      grid-template-columns: repeat(1, minmax(0, 2fr));

    }
}