.mobileToggler{

    outline: 0;
	border: none;
	cursor: pointer;
	background-color: transparent;
	position: relative;
	height:fit-content;
	width:fit-content;
	align-self: center;
	padding: 0 20px;
	z-index: 1000;
}