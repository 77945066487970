.container{
  font-family: var(--font-family);
    h1 {
        font-size: 3.7rem;
        font-weight: 700;
        line-height: 1;
        margin: 0 0 20px;
        font-size: min(3.7rem, 6.7rem, 13.7rem);
      }
      
      h2 {
        font-size: 2.2rem;
        font-weight: 400;
        line-height: 1.4;
        opacity: 0.75;
        margin: 0;

      }


      .wavytext{

        
      }

}