.container{
  width: fit-content;
  padding: 0 20px;
  margin-left: auto;

}



.toggler{
  cursor: pointer;
  
  position: relative;
  width: 80px;
  height: 38px;
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 6.25px;
  justify-content: flex-start;
  overflow: hidden;
  transition: border-color 0.3s linear;
  


    &::before{
      content: "";
      position: absolute;
      border-radius: 50%;
      right: 10%;
      top: 15%;
      transition: transform 0.3s linear;
      background-color: #fff;
      position: absolute;
      width: 15px;
      height: 15px;
      z-index: 2;
      transition: transform 0.3s linear;
      transition-delay: 150ms;
     

    }

    &::after{
      content: "";
      position: absolute;
      border-radius: 50%;
      right: 30%;
      top: 60%;
      background-color: #fff;
      position: absolute;
      width: 10px;
      height: 10px;
      z-index: 2;
      transition: transform 0.3s linear;
      transition-delay: .3s;

    }



    div{
      position: absolute;
      background: #fff;

      &:nth-of-type(1){
        top: 70%;
        left: 25%;
        width: 10px;
        height: 8px;
        border-radius: 50%;

      }
      &:nth-of-type(2){
        top: 16%;
        left: 8%;
        width: 15px;
        height: 15px;

        clip-path: polygon(50% 0%, 63% 38%, 100% 38%, 69% 59%, 82% 100%, 50% 75%, 18% 100%, 31% 59%, 0 38%, 37% 38%);
      }
      &:nth-of-type(3){
        top: 60%;
        left: 45%;
        width: 6px;
        height: 6px;

        border-radius: 58%;
        clip-path: polygon(50% 0%, 63% 38%, 100% 38%, 69% 59%, 82% 100%, 50% 75%, 18% 100%, 31% 59%, 0 38%, 37% 38%);
      }
      &:nth-of-type(4){
        top: 40%;
        left: 35%;
        width: 2px;
        height: 2px;

        border-radius: 50%;
      }
      &:nth-of-type(5){
        top: 15%;
        left: 45%;
        width: 6px;
        height: 6px;

        border-radius: 50%;
      }

    }

}


.toggler.dark{
  justify-content:flex-end;
  border: 1px solid #d0c98b;
  border-color:rgba(145,115,235,.5);
 

    &::before{
      transform: translateY(100px);
      transition: transform 0.3s linear;
      
    }
    &::after{
      transform: translateY(100px);
      transition: transform 0.3s linear;
      
    }


}


.switch{
    position: absolute;
    border-radius: 50%;
    box-shadow: #d0c98b 0px 8px inset, #d0c98b 0px 8px 1px 1px inset;
    background: transparent none repeat scroll 0% 0%;
    transform: translate3d(0px, 0px, 0px) rotate(90deg) scale(1);
    width: 65px;
    height: 35px;
    border-radius: 100px;
    transition: all 300ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
    width: 50px;
    height: 50px;
    z-index: 9999;
    border-radius: 50px;
}

.switch.on {
    background-color: #dddddd;
    justify-content: flex-start;
}

  .switch.off {
    background-color: rgb(26, 32, 44);
    justify-content: flex-end;
}
  
  .switch div {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    box-shadow: #d0c98b 0px 13px inset, #d0c98b 0px 13px 1px 1px inset;
    background: transparent none repeat scroll 0% 0%;
    transform: translate3d(0px, 0px, 0px) rotate(90deg) scale(1);
    transform-origin: 50% 50% 0px;
}