:root{
    --rotate {
        syntax: "<angle>";
        initial-value: 132deg;
        inherits: false;
    }

    --card-height: 65vh;
    --card-width: calc(var(--card-height) / 1.5);
}

.container.dark{
    background: linear-gradient(90deg, rgb(55, 7, 110)  11%, rgb(32, 2, 66) 83%);;
    border-color:rgba(43, 35, 68,0.3) ;

        .inner{
            background: radial-gradient(circle at 50% 50%, rgb(255, 255, 255) 5%, rgb(255, 255, 255) 65%, rgba(238, 130, 238, 0) 100%);
            background: radial-gradient(circle at 50% 50%, rgb(28, 13, 46) 5%, rgb(32, 2, 66) 78%, rgba(238, 130, 238, 0) 100%);
            color: #E4E6EB;
        }
    
}

.container{



    width: 275px;
    border-radius: 5px;
    border: 1px solid;
    border-color:#E4E6EB ;
    box-shadow: rgba(0, 0, 0, 0.3) 0px 2px 4px 0px;
    background-color: #E4E6EB;
    position: relative;
    overflow: hidden;
    width: var(--card-width);
    background: rgba(0, 0, 0, 0) linear-gradient(rgb(178, 187, 200) 0%, rgb(223, 223, 223) 32%, rgb(245, 249, 255) 61%, rgb(255, 255, 255) 88%);
    font-family: var(--font-family);

    
    // &::before{
    //     content:"";
    //     position: absolute;
    //     width : calc(100% + 10px);
    //     height : calc(100% + 10px);
    //     background-image: conic-gradient(red, purple, blue, yellow, orange, red);
    //     z-index : -1;
    //     border-radius:3px;
    //     filter:blur(5px);
    //     }

        // &::before{
        //     content: "";
        //     width: 104%;
        //     height: 102%;
        //     border-radius: 8px;
        //     background-image: linear-gradient(
        //       var(--rotate)
        //       , #5ddcff, #3c67e3 43%, #4e00c2);
        //       position: absolute;
        //       z-index: -1;
        //       top: -1%;
        //       left: -2%;
        //       animation: spin 2.5s linear infinite;
        // }
        
        // &::after{
        //     position: absolute;
        //     content: "";
        //     top: calc(var(--card-height) / 6);
        //     left: 0;
        //     right: 0;
        //     z-index: -1;
        //     height: 100%;
        //     width: 100%;
        //     margin: 0 auto;
        //     transform: scale(0.8);
        //     filter: blur(calc(var(--card-height) / 6));
        //     background-image: linear-gradient(
        //       var(--rotate)
        //       , #5ddcff, #3c67e3 43%, #4e00c2);
        //       opacity: 1;
        //     transition: opacity .5s;
        //     animation: spin 2.5s linear infinite;
        // }

        // @keyframes spin {
        //     0% {
        //       --rotate: 0deg;
        //     }
        //     100% {
        //       --rotate: 360deg;
        //     }
        //   }


     
}

.inner{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 5px;
    margin: 6px;
    position: relative;
    z-index: 2;
   
    // background: radial-gradient(circle at 50% 50%, rgb(255, 255, 255) 5%, rgb(255, 255, 255) 65%, rgba(238, 130, 238, 0) 100%);
   background: white;

 
}

.inner.dark{
    &.dark{
        background: linear-gradient(90deg, rgb(55, 7, 110) 11%, rgb(32, 2, 66) 83%);
       }
}

.image{

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    height: 200px;
    width: 100%;
 
    position: relative;
    overflow: hidden;
    

    > header {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 5px 1px;
        background: inherit;
        
        &::before{
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: inherit;
            background-attachment: fixed;
            
            filter: blur(12px);
            transform: scale(2) translateY(2px);
            background:#E4E6EB
        }

        h1{
            margin: 0;
            color: rgb(43, 35, 68);
            position: relative;
            z-index: 1;
        }

    }

}

.desc{
    
    padding-bottom: 5px;
    min-height: 80px;



        h3{
            font-size: 0.8rem;
            font-weight: 400;
            letter-spacing: 0.03rem;
        }
}


.tags{

    display: flex;
    justify-content: space-around;
    width: 100%;

    padding-bottom: 10px;
  
   

    div{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

  

            h4{
                font-size: 0.8rem;
                font-weight: 400;
                letter-spacing: 0.03rem;
            }
       
    }

    img{
        height:80px;
        width: 80px;
        object-fit: contain;
    }
}


.cardLinks{
    display: flex;
    justify-content: space-around;
    padding-bottom: 5px;
    width: 100%;
    height: 30px;

   


        a{
            display: flex;
            align-items: center;
      
         

            &:hover{
                opacity: 0.75;
            }

                img{
                    min-height: 25px;
                    min-width: auto;
                    height:100%;
                    width: 100%;
                    object-fit: contain;
                    padding-right: 10px;
                }
                svg{
                    height: 14px;
                    min-width: auto;
                
                    width: 100%;
                    padding-right: 10px;
                }
            
                h2{
                    white-space: nowrap;
                    font-weight: 600;
                    letter-spacing: 0.045rem;
                    font-size: 0.68rem;
                }
            
        }

}