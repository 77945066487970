.container{

    background:"";

}


section{

position: relative;
height: 100%;
display:flex;
flex-direction:column;
justify-content:space-between;
align-items:center;
min-height: 100vh;
height: 100%;

    &:nth-of-type(1){
        // background: url("data:image/svg+xml,%3Csvg width='1280' height='233' xmlns='http://www.w3.org/2000/svg'%3E %3Cg fill='none' fill-rule='evenodd'%3E %3Cpath d='M1280 0c-167.463 0-449.312 114.933-639.815 114.933C449.68 114.933 173.4 0 0 0v233h1280V0z' fill='%23fa6762'/%3E %3Cpath d='M1280 140.13C1112.537 140.13 830.503 42 640 42S173.4 140.13 0 140.13v93h1280v-93z' fill='%23fa4f4a'/%3E %3C/g%3E %3C/svg%3E ") bottom/100% no-repeat fixed;

        
      }
      &:nth-of-type(2){
        //background: url("data:image/svg+xml,%3Csvg width='686' height='644' xmlns='http://www.w3.org/2000/svg'%3E %3Cg fill='%23fa4f4a' fill-rule='evenodd'%3E %3Cpath d='M140.515 611.116C-12.61 495.728-45.706 281.379 66.592 132.353 178.892-16.673 394.06-43.941 547.185 71.446c153.125 115.388-83.89 141.804-196.188 290.83-112.299 149.026-57.357 364.228-210.482 248.84z' opacity='.8'/%3E %3Cpath d='M427 533.716c143.042 0 259-60.441 259-135 0-74.558-190.587-12.488-333.629-12.488-143.041 0-184.371-62.07-184.371 12.488 0 74.559 115.958 135 259 135z' opacity='.6'/%3E %3C/g%3E %3C/svg%3E") center/auto 100% no-repeat fixed;
   
      }
      &:nth-of-type(3){
        //background: url("data:image/svg+xml,%3Csvg width='1280' height='433' xmlns='http://www.w3.org/2000/svg'%3E %3Cpath d='M0 0h1280L0 433z' fill='%23fa4f4a ' fill-rule='evenodd'/%3E %3C/svg%3E ") top/100% no-repeat fixed;
        padding-bottom: 20px;
      }


      .innerContent{
        position: relative;
        z-index: 2;

        &.one{
          width: 345px;
          
        }
       

     

        &.three{
    
          width: 100%;
          margin-top: auto;
          display: flex;
          justify-content: center;
        }
      }
    
}



.motionCardWrap{
  display: grid;
  grid-gap: 0.5rem;
  grid-template-columns: repeat(2, minmax(0, 2fr));
  justify-items:center; 

  @media screen and (min-width: 80px) and (max-width: 675px){            
    grid-template-columns: repeat(1, minmax(0, 2fr));

  }
}