.navbar{
    height: 60px;
    position: fixed;
    display:flex;
    align-items: center;

    position: fixed;
    top:0;
    left:0;
    right:0;
    z-index:1000;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.15);
    border-bottom: 1px solid;
    border-color: rgba(145, 115, 235,0.5); 
    font-family: var(--font-family);

 

}


.navbar.dark{
  border-bottom: 1px solid;
  border-color: rgba(145, 115, 235,0.5); 

}


.logo{
  font-family: "Michroma";
  padding-left: 15px;
  h1{
    font-size: 2rem;
    span{

    }
  }
}



.shownList{
    display: flex;
    flex: 1;
    justify-content: end;
    background: var(--gradientBgPurple);
    background-clip: text;
    gap: 20px;
    margin-left: auto



    a {
        text-decoration: none;
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: var(--font-family);
        font-size: 1.2rem;
        font-weight: 400;
        letter-spacing: .05rem;
      }

        li{
          list-style: none;
        }
    
     
    
        span{
          position: relative;
          font-size: 1.3rem;
          font-weight: 600;
    
            &:after{
            content:"";
            height: 2px;
            background: var(--gradient-background);  
            position: absolute;
            left: 0;
            right: 0;   
            bottom: -6px;
            opacity: 0;
            transform-origin: left center; 
            transition: all 250ms cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s;
            transform: scaleX(0);              
            }
          }
    
            &:hover {
              span:after{
                transform: scaleX(1);
                opacity: 1;
              }
            } 

  

}



.hiddenList{

}

.hiddenList__inner{

}




.motionMobileMenu{
  background: #202124;
  position: absolute;
  width: 100%;
  z-index: 99;




  .hiddenList__inner{
    background: rgb(145, 115, 235);
    height: 100vh;
    padding-top: 60px;
   
    li{
      list-style: none;
      padding: 20px;
    }


  }

  .hiddenList__inner.dark{
    background: rgb(43, 35, 68);

    li{
      list-style: none;
      padding: 20px;
    }
  }
}