.container{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    line{
   
        stroke: rgb(145, 116, 233);
        stroke-width: 1px;
    }
}


.container.dark{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    line{
   
        stroke: rgba(233, 199, 255, 0.822);
        stroke-width: 1px;
    }

}