


:root {
	--font-family: 'Montserrat', sans-serif;
	--neon-font: 'Codystar', 'Monoton';
	--fontsize-regular: 1rem;
	--fontsize-big: 2rem;
	--fontsize-small: 0.8rem;

    
	--gradient-text: linear-gradient(89.97deg, #ae67fa 1.84%, #f49867 102.67%);
	--gradient-bar: linear-gradient(103.22deg, #ae67fa -13.86%, #f49867 99.55%);
	--gradientBgRed: linear-gradient(90deg, rgb(243, 113, 152) 11%, rgb(165, 0, 41) 83%);
	--gradientBgPurple: linear-gradient(90deg, rgb(145, 115, 235)  11%, rgb(67, 6, 138) 83%);

	--darkTogglerBg:linear-gradient(180deg, rgba(255, 0, 6, 1) 0%, rgba(245, 115, 20, 1) 51%, rgba(239, 204, 124, 1) 100%);
	--mainRed: rgba(165, 0, 41, 1);
	--darkRed: rgb(116, 0, 29);
	--dark: #242526;
	--grey: #B0B3B8;
	--light: #E4E6EB;
	
	
	--color-text: #81afdd;
	--color-subtext: #ff8a71;
	--cardTitleGradient:linear-gradient(165deg, #ff6694, #690000);
	--cardBackgroundGradient:linear-gradient(43deg, #d0417d 0%, #be2659 46%, #bb1d32 100%); 
	--silver: linear-gradient( -72deg, #dedede, #ffffff 16%, #dedede 21%, #ffffff 24%, #454545 27%, #dedede 36%, #ffffff 45%, #ffffff 60%, #dedede 72%, #ffffff 80%, #dedede 84%, #a1a1a1 );



    --googleDark: #202124;
	--light-purple: rgb(145, 115, 235);
	--main-purple: rgb(90, 0, 192);
	--dark-purple: rgb(67, 6, 138);
	--dark-purple2:rgb(43, 35, 68);

	--color: hsl(340, 96%, 30%);
	--color2: hsl(213, 85%, 49%);
	--color3: hsl(150, 45%, 40%);
}